.schedule__calls__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
/* Filters */
.filters {
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
  border: 1px solid #e0e6ed;
  padding: 15px 20px;
  border-radius: 6px;
  margin-top: 15px;
  margin-bottom: 30px;
}
.filters h3 {
  font-size: 18px;
  font-weight: 500;
}
.filters .filter__checkboxes {
  margin: 20px 0;
  display: flex;
  align-items: center;
}
.filters .filter__checkboxes label {
  margin-right: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.filters .filter__checkboxes span {
  margin-left: 5px;
  margin-top: -5px;
}

.filters .filter__buttons {
  display: flex;
  justify-content: flex-end;
}
.filter__buttons button {
  font-size: 15px;
}

/* No Results */
.no__results {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #777;
}
.no__results svg {
  font-size: 50px;
  margin-bottom: 10px;
}
.no__results p {
  font-size: 16px;
  font-weight: 500;
}
