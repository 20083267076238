.error__page__container {
  width: 100%;
  height: 88vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fafafa;
}

.error__page__container img {
  width: 800px;
}

.error__page__container button {
  border-radius: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
