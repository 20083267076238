.customer__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.big__modal .modal-dialog {
  max-width: 998px !important;
}

.full__modal .modal-dialog {
  max-width: 1168px !important;
}

.checkbox__container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 20px;
}

.customer__Search {
  width: 90vw;
  max-width: 350px;
  margin-bottom: 30px;
}
