* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  outline: none;
}
::placeholder {
  font-weight: 400;
}

.container {
  width: 90vw;
  max-width: 1168px;
  margin: 50px auto;
}

/* Buttons */
.action__btn {
  display: grid;
  place-items: center;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
.actions__btn__container {
  display: flex;
  align-items: center;
}
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Error message */
.error__msg {
  display: flex;
  align-items: center;
  color: red;
  font-size: 16px;
}
.error__msg svg {
  font-size: 20px;
}

.modal-header {
  align-items: flex-start !important;
}

/* analytics */

.chart-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.chart-title h2 {
  font-size: 20px;
}

select {
  width: 200px;
}

.chart-title2 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.chart-title2 h2 {
  font-size: 20px;
}

select {
  width: 200px;
}
