.productGroup__form input {
  padding: 7px 10px;
  border-radius: 5px;
  box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
  border: 1px solid #e0e6ed;
  font-size: 16px;
  display: grid;
  place-items: center;
}

.productGroup__form {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 400px;
  width: 90vw;
}
