a {
  text-decoration: none !important;
  color: #000 !important;
  font-weight: 400;
  font-size: 16px;
  padding: 10px;
}

.navbar .active__link {
  color: #006699 !important;
  font-weight: 600;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.logout__btn {
  margin-left: 20px;
}

@media (max-width: 992px) {
  .logout__btn {
    margin: 10px;
  }
}
