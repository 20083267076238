.salesrepresentative__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.performance-graph-div {
  width: 100%;
  max-width: 600px;
  margin: 80px 0;
}
