.pfi_container {
  margin: 0;
  padding: 20px;
  /* border: 1px solid #dee2e6; */
  border: 1px solid #000;
  margin-top: 10px;
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.pfi_container p {
  margin: 0;
  line-height: 1.5;
  font-size: 12px !important;
}

.pfi_container h3 {
  font-size: 14px;
  font-weight: 700;
}

.pfi_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 15px;
}

.logo_container {
  flex: 5;
}

.buyer_details,
.other_details,
.bank_details {
  margin: 15px 0;
  padding: 8px 12px;
  border: 1px solid #000;
  border-radius: 10px;
}

.buyer_details span,
.other_details span,
.bank_details span,
.pfi_footer .contacts span {
  text-transform: uppercase;
  font-weight: 500;
}

.other_details > div,
.buyer_details > div {
  margin: 4px 0;
}

.other_details > *,
.buyer_details span {
  font-size: 12px;
}
.other_details > div,
.buyer_details > div {
  display: flex;
  align-items: center;
}
.other_details div > span {
  flex: 1;
}
.other_details div > p {
  flex: 1;
}

.buyer_details div > span {
  flex: 1;
}
.buyer_details div > p {
  flex: 3;
}

.other_details_container {
  flex: 4;
}
.other_details_container .heading {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 600;
}

.product_table table {
  width: 100%;
  margin-bottom: 15px;
  font-size: 12px !important;
}

.product_table table,
.product_table th,
.product_table td {
  border: 1px solid #000;
  border-collapse: collapse;
  padding: 3px 6px;
}

.bank_details table {
  width: 100%;
  margin: 10px 0;
  font-size: 12px !important;
}

.bank_details table th {
  font-weight: 700;
}

.image_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px 0 20px 0;
}
.image_container div {
  flex: 1;
}
.image_container img {
  width: 60px !important;
  margin: 0 10px;
}

.pfi_footer p {
  font-size: 12px;
}

.pfi_footer .contact_details {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #000;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.pfi_footer .contact_details img {
  flex: 1;
}

.pfi_footer .contacts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;
  gap: 10px;
}

.pfi_footer .contacts P {
  font-size: 9px !important;
}

.pfi_footer .services {
  text-align: center;
}

.pfi_footer .social {
  display: flex;
  align-items: center;
}
.pfi_footer svg {
  cursor: pointer;
}
.pfi_footer a {
  padding: 0 5px;
}
