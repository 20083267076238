.login__container {
  width: 100%;
  height: 100vh;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login__container form {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%), 0 2px 5px 0 rgb(0 0 0 / 5%);
  padding: 30px 50px;
  border-radius: 15px;
  max-width: 500px;
  width: 90vw;
  margin: auto;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.8);
}

.login__container img {
  width: 80%;
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.login__container .form__row {
  width: 100%;
  margin-bottom: 30px;
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.login__container .form__row svg {
  font-size: 25px;
  color: #888;
  margin-top: 5px;
}
.login__container .form__row input {
  padding: 7px 15px;
  border-radius: 5px;
  border: none;
  margin-right: 10px;
  font-size: 16px;
  margin-top: 5px;
  flex: 1;
  outline: none;
  font-weight: 500;
  outline: none;
}

.login__container .form__row > * {
  display: block;
}

.login__container .btn__container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login__container button {
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
}

.show__password {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.form-check-input {
  /* background: #198754 !important; */
  outline: none !important;
}
